<template>
  <div class="h-screen flex items-center justify-center flex-col">
    <img
      src="../assets/404Img.png"
      alt="404 Not Found"
      class="h-3/4 mt-20 md:h-auto md:mt-0"
    />
    <router-link to="/">
      <button
        class="
          bg-blue-500
          hover:bg-blue-700
          text-white
          font-bold
          py-2
          px-6
          rounded
          mt-4
        "
      >
        Return To Home
      </button>
    </router-link>
  </div>
</template>